import logo from './logo.svg';
import './App.css';
import "./scss/styles.scss";
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Main from './screens/Main';
import Home from './screens/Home';
import Lectura from './screens/Lectura';
import { useEffect, useState } from 'react';
import { sistema } from "./model/Sistema";
import { useInterval } from './lib/useInterval';
import { getSistemaData, setSistemaData } from "./lib/serverAPI";
import Usuario from "./model/Usuario";
import { login, logout } from "./lib/auth";
import APIManager from './lib/apiManager';

const mdTheme = createTheme({
  palette: {
    mode: 'dark',
    backgroundColor: 'black',
    background: {
      default: 'black',
      paper: 'black',
      body: 'black',
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      backgroundColor: 'black',
      // main: '#ffbb88',
      main: '#ffeeaa',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
});

function App({version, ...props}) {
  // const [accesoID, setAccesoID] = useState(localStorage.getItem('accesoID') ?? '');
  const [isLoading, setIsLoading] = useState(true);
  const [usuario, setUsuario] = useState(null);
  const [prestador, setPrestador] = useState(null);
  const successSound = new Audio('/notification.mp3');

  const setLoginData = (usuarioData) => {
    //sistema.usuario = new Usuario(usuarioData);
    sistema.usuario = new Usuario(usuarioData);
    let sistemaData = getSistemaData();
    sistema.noImageURL = sistemaData.noImageURL;
    setUsuario(sistema.usuario);

    window.sistema = sistema; /*** COMENTAR REFERENCIA PARA DEPLOY ***/
  };

  const doLogout = () => {
    setIsLoading(true);
    return logout(setUsuario);
  };

  useEffect(() => {
    if (usuario && isLoading) {
      APIManager.getPrestador((data) => {
        sistema.prestador = data.prestador;
        setPrestador(sistema.prestador);
        setSistemaData(sistema);
        setIsLoading(false);
      }, error => {
        doLogout();
      });
    }

    const doLogin = () => {
      return login(setLoginData);
    };

    if (!usuario) {
      doLogin();
    }

    if (!!usuario && !isLoading && !!process.env.REACT_APP_ENV && !document.title.includes(process.env.REACT_APP_ENV)) {
      document.title = document.title + ' (' + process.env.REACT_APP_ENV + ')';
    }
  }, [usuario, isLoading]);

  return (
    isLoading
      ? <div style={{ flexDirection: 'column', backgroundColor: 'white', height: '100vh', width: '100vw', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
        <p>La Maquinita Co. - Check-in de Beneficios</p>
        <p>{'v'+version }</p>
      </div>
      : <>
        <CssBaseline enableColorScheme={true} />
        <ThemeProvider theme={mdTheme}>
          <BrowserRouter>
            <Routes>
              <Route path="/login" action={doLogout} element={<Home logout={doLogout} /> } />
              <Route path="/" element={<Main {...props} usuario={usuario} prestador={prestador} logout={ doLogout}  />}>
                <Route path="home" element={<Home {...props} usuario={usuario} prestador={prestador} logout={ doLogout } />} />
                <Route path="lectura" element={<Lectura {...props} usuario={usuario} prestador={prestador} successSound={successSound} />} />
              </Route>

            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </>
  );
}

export default App;
