import { Menu as MenuIcon } from "@mui/icons-material";
import { AppBar, Container, IconButton, Toolbar } from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { sistema } from "../../model/Sistema";
import { getSistemaData } from "../../lib/serverAPI";

const Main = ({ usuario, prestador, logout }) => {
  // const [usuario, setUsuario] = useState(sistema?.usuario ?? null);
  // const [prestador, setPrestador] = useState(sistema?.prestador ?? null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleGoHome = () => {
    if (location.pathname !== '/home') {
      navigate('/home');
    } else {
      navigate('/lectura');
    }
  }

   useEffect(() => {
    if (!!usuario && (location.pathname === '/login')) {
      logout();
    } else if (!!usuario && !!prestador && (location.pathname === '/')) {
      navigate('/lectura');
    }
  }, [usuario, prestador]);

  return (
    <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: '100vw' }}>
      <AppBar color="transparent">
        <Toolbar>
          <IconButton onClick={handleGoHome}><MenuIcon /></IconButton>
        </Toolbar>
      </AppBar>
      <Outlet/>
    </Container>
  );
}

export default Main;