import axios from "axios";
import {
  v4 as uuidv4,
  parse as uuidParse,
  stringify as uuidStringify,
} from "uuid";

export const getUUID = () => {
  let myUUID = JSON.parse(localStorage.getItem("uuid"));
  if (!myUUID) {
    myUUID = uuidv4();
    myUUID = uuidParse(myUUID);
    localStorage.setItem("uuid", JSON.stringify(myUUID));
  }
  return uuidStringify(myUUID);
};

export const setAuthToken = (newToken) => {
  localStorage.setItem("authToken", newToken);
  document.cookie = "authToken=" + newToken;
};

export const getAuthToken = (newToken) => {
  if (newToken) {
    setAuthToken(newToken);
  }
  const authToken = localStorage.getItem("authToken");
  document.cookie = "authToken=" + authToken;
  return authToken;
};

export const sendRequest = (tipo, endpoint, accion, data, callback) => {
  const URL_PATH = process.env.REACT_APP_API_URL;
  let URL_FULL = "";

  if (!data) {
    data = {};
  }

  data.uuid = getUUID();
  data.authToken = getAuthToken();
  data.app = "Beneficios";

  switch (tipo) {
    case "get":
      URL_FULL = `${URL_PATH}/${endpoint ? endpoint + "/" : accion}${
        data ? "?data=" + JSON.stringify(data) : ""
      }`;
      axios.get(URL_FULL, { withCredentials: true }).then((response) => {
        if (response && response.data) {
          if (response.data.success) {
            if (response.data.authToken) {
              setAuthToken(response.data.authToken);
            }
            setSistemaData({ noImageURL: response.data.noImageURL, verFotosDeSedes: (typeof(response.data.verFotosDeSedes) === 'undefined' ? false : response.data.verFotosDeSedes)});
            callback(response.data);
          }
        }
      });
      break;
    default:
      data.accion = accion;
      URL_FULL = `${URL_PATH}/${endpoint ? endpoint + "/" : accion}`;
      axios.post(URL_FULL, data, { withCredentials: true }).then((response) => {
        if (response && response.data) {
          if (response.data.success && response.data.authToken) {
            setAuthToken(response.data.authToken);
          }
          setSistemaData({ noImageURL: response.data.noImageURL, verFotosDeSedes: (typeof(response.data.verFotosDeSedes) === 'undefined' ? false : response.data.verFotosDeSedes)});
          callback(response.data);
        }
      });
      break;
  }
};

export const getUIData = (accion, postData = {}, callback, errorCallback) => {
  sendRequest("post", "ui", accion, postData, (response) => {
    if (response.success) {
      if (callback) {
        callback(response.data)
      };
    } else {
      if (errorCallback) {
        errorCallback(response);
      }
    }
  });
};

export const setSistemaData = (sistemaData) => {
  localStorage.setItem("mqcobSistema", JSON.stringify(sistemaData));
};

export const getSistemaData = () => {
  return JSON.parse(localStorage.getItem("mqcobSistema") ?? '{}');
};
