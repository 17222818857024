import { useEffect, useState } from "react";
import { useInterval } from "../../lib/useInterval";
import APIManager from '../../lib/apiManager';
import LoadingDialog from "../../components/LoadingDialog";
import { ArrowBack, ArrowDownward, ArrowForward, Cancel, CancelOutlined, Check, CheckCircle, Height, QrCode2 } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { sistema } from "../../model/Sistema";
import BeneficioCard from "../../components/BeneficioCard";
import MaquineroCard from "../../components/MaquineroCard";

const { Paper, Container, Typography, Stack, Dialog, DialogTitle, DialogContent, Box, IconButton } = require("@mui/material");
const { QrReader } = require("react-qr-reader")

const Lectura = ({ usuario, prestador, version, successSound }) => {
  const navigate = useNavigate();

  const [active, setActive] = useState(false);
  const [data, setData] = useState(null);
  const [dataPrevia, setDataPrevia] = useState(null);

  const [side, setSide] = useState('right');

  const [esperandoResultado, setEsperandoResultado] = useState(false);

  const [beneficio, setBeneficio] = useState(null);
  const [usuarioDeBeneficio, setUsuarioDeBeneficio] = useState(null);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openConfirmado, setOpenConfirmado] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleQRResult = (result, error) => {
    if (!!result) {
      setData(result?.text);
    }

    if (!!error) {
      setData(null);
    }
  }

  const hacerCheckIn = (codigo, successCallback) => {
    setEsperandoResultado(true);
    APIManager.sendRequest('post', 'beneficios', 'checkIn', { codigo: codigo, prestador: prestador }, response => {
      setEsperandoResultado(false);
      if (response.success) {
        setErrorMessage(null);
        if (!!successCallback) {
          successCallback(response.data);
        }
      } else {
        // Devolvió error
        handleError(response);
      }
    })
  }

  const handleError = (response) => {
    // Administra el error de la respuesta del check-in
    const readBeneficio = response.data.beneficio;
    const readMessage = (!!response.errors.length > 0) ? response.errors[0].message : response.message;
    console.log('handleError', response, readBeneficio, readMessage);
    setBeneficio(readBeneficio);
    setErrorMessage(readMessage);
    setOpenError(true);
  }

  useEffect(() => {
    if (!usuario || !prestador) {
      navigate('/home');
    }
  }, []);

  const doBeep = () => {
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    const oscillator = audioCtx.createOscillator();
    // oscillator.type = "square";
    oscillator.type = "sine";
    oscillator.frequency.setValueAtTime(640, audioCtx.currentTime); // value in hertz
    oscillator.connect(audioCtx.destination);
    oscillator.start();
    oscillator.stop(0.1);
  }

  const checkInPermitido = (responseData) => {

    successSound.play();
    setBeneficio(responseData.beneficio);
    setUsuarioDeBeneficio(responseData.usuario);
    setOpenSuccess(true);
    // setTimeout(() => { setOpenSuccess(false); }, 3000);
  }

  const handleConfirmarCheckin = () => {
    // Confirma el uso del beneficio
    setOpenSuccess(false);
    setEsperandoResultado(true);
    APIManager.sendRequest('post', 'beneficios', 'confirmarCheckIn', { beneficio: beneficio, usuario: usuarioDeBeneficio }, response => {
      setEsperandoResultado(false);
      console.log('Confirmar response', response);
      if (response.success) {
        setErrorMessage(null);
        setBeneficio(null);
        setUsuarioDeBeneficio(null);
        setOpenConfirmado(true);
        setTimeout(() => { setOpenConfirmado(false); }, 2000);
      } else {
        // Devolvió error
        handleError(response);
      }
    })

  }

  const handleChangeSide = () => {
    if (side === 'left') {
      setSide('right');
    } else {
      setSide('left');
    }
  }

  useInterval(() => {
    if (!!data && data !== dataPrevia) {
      // Tenemos una nueva lectura!
      doBeep();
      hacerCheckIn(data, checkInPermitido);
      // Evitamos la repeticion
      setDataPrevia(data);
      // Habilitamos timeout para permitir check-in del mismo codigo
      setTimeout(() => { setDataPrevia(null) }, 3000);
    }
  }, 500);

  return (
    <Box className="lectura confirmado" width="100vw" onClick={handleChangeSide}>
      {!!esperandoResultado && <LoadingDialog open={true} />}
      <Dialog className="lectura" open={openConfirmado} fullWidth={true} onClose={() => setOpenConfirmado(false)}>
        <DialogContent className="dialog" sx={{ backgroundColor: "green" }} >
          <Stack height="100%" justifyContent="center" alignItems="center">
            <CheckCircle sx={{ width: "50vw", height: "50vh"}} />
          </Stack>
        </DialogContent>
      </Dialog>
      <Dialog className="lectura success" open={openSuccess&&!!beneficio} fullWidth={true}  onClose={() => setOpenSuccess(false)}>
        <DialogContent className="dialog" sx={{backgroundColor: "white"}}>
          <Box>
            <Stack flexDirection="column" justifyContent="space-evenly" alignItems="center">
              <Stack flexDirection="column" justifyContent="space-evenly" alignItems="center">
                {!!beneficio && <BeneficioCard {...beneficio} />}
                {!!usuarioDeBeneficio && <MaquineroCard {...usuarioDeBeneficio} />}
              </Stack>
              <Stack flexDirection="row" justifyContent="space-evenly" alignItems="center" width="100%">
                <IconButton onClick={handleConfirmarCheckin}><CheckCircle className="check"/></IconButton>
                <IconButton onClick={() => setOpenSuccess(false)}><Cancel className="cancel"/></IconButton>
              </Stack>
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog className="lectura error" open={openError&&!!errorMessage} fullWidth={true} onClose={() => setOpenError(false)}>
        <DialogContent className="dialog" sx={{backgroundColor: "white"}}>
          <Box>
            <Stack flexDirection="column" justifyContent="space-evenly" alignItems="center">
              <Stack flexDirection="column" justifyContent="space-evenly" alignItems="center">
                {!!beneficio && <BeneficioCard {...beneficio} />}
                {!!errorMessage && <div className="beneficio-card__titulo"><Typography fontSize="2rem" component="h1">{ errorMessage }</Typography></div>}
              </Stack>
              <Stack flexDirection="row" justifyContent="space-evenly" alignItems="center" width="100%">
                <IconButton onClick={() => setOpenError(false)}><CheckCircle className="check"/></IconButton>
              </Stack>
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
      <Stack flexDirection="column" alignItems="center" justifyContent="flex-start" height="100%">
        <Stack className="qr--info" flexDirection="column" alignItems="center" justifyContent="center">
          <Typography color="primary" className="qr--texto">Solicite el QR del beneficio</Typography>
          <Stack direction="column" alignItems="center">
            <QrCode2 className="qr--icon" color="primary" />
            <ArrowDownward className="qr--arrow" color="primary" />
          </Stack>
          {/* <Typography color="primary" className="qr--texto">dentro del cuadro</Typography> */}
        </Stack>
        {/* <ArrowDownward className="qr--arrow" color="primary" /> */}
        <Stack direction="column" alignItems="center" justifyContent="center">
          <QrReader
            constraints={{ facingMode: { 'ideal': 'environment' } }}
            className="qr-reader--scanner"
            scanDelay={500}
            onResult={handleQRResult}
          />
          <Typography color="primary" className="qr--version">{'v' + version}</Typography>
        </Stack>
      </Stack>
    </Box>
  )

}

export default Lectura;