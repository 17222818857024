import { Check, Fullscreen, FullscreenExit, Logout, SaveAlt } from "@mui/icons-material";
import { Box, Container, IconButton, Paper, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sistema } from "../../model/Sistema";
import { getSistemaData } from "../../lib/serverAPI";

const Home = ({ usuario, prestador, logout }) => {
  // const [usuario, setUsuario] = useState(sistema?.usuario ?? null);
  // const [prestador, setPrestador] = useState(sistema?.prestador ?? null);
  const [prestadorNombre, setPrestadorNombre] = useState(sistema?.prestador?.nombre ?? '');
  const navigate = useNavigate();

  const handleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.body.requestFullscreen().then(() => {
        // window.screen.orientation.lock('landscape').catch(error => { console.log(error) });
      });
    } else {
      document.exitFullscreen().then(() => {
        // window.screen.orientation.unlock();
      });
    }
  }

  useEffect(() => {
    console.log('prestador:', prestador);
    if (!usuario || !prestador) {
      logout();
    }
    setPrestadorNombre(prestador?.nombre ?? '');
  }, [usuario, prestador]);

  /* useEffect(() => {
    if (!sistema.usuario || !sistema.prestador) {
      sistema = getSistemaData();
      setUsuario(sistema.usuario);
      setPrestador(sistema.prestador);
    }
  }, []);
 */
  return (
    <Stack direction="column" alignItems="center" onClick={()=>{}}>
      <Typography variant="h3" color="primary" marginBlockEnd={2}>MQco Check-in de Beneficios</Typography>
      <Stack direction="row" alignItems="center">
        <TextField type="text" label="Prestador" value={prestadorNombre} disabled={true}></TextField>
        <IconButton onClick={logout}><Logout /></IconButton>
      </Stack>
      <IconButton onClick={handleFullScreen}>{!document.fullscreenElement ? <Fullscreen /> : <FullscreenExit />}</IconButton>
    </Stack>
  );
}

export default Home;