import React from "react";
import { sistema } from "../../model/Sistema";

export default ({ nombres, apellidos, imagen, ...props }) => {

  return (
    <div className="maquinero-card">
      <div className="maquinero-card__avatar">
        <i className="maquinero-card__avatar__notification-icon icon--circle hidden"></i>
        {imagen
          ? <div className="maquinero-card__avatar__image" src={imagen} style={{backgroundImage: `url(${imagen})`}} alt="" />
          : <div className="maquinero-card__avatar__image">
              <span className="maquinero-card__avatar__text">
                {nombres[0]+apellidos[0]}
              </span>
            </div>
        }
      </div>
      <h1 className="maquinero-card__nombre">
        {nombres + (apellidos ? " " + apellidos : "")}
      </h1>
    </div>
  );
};
