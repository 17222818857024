import { sistema } from '../model/Sistema';
import { sendRequest, setAuthToken, setSistemaData } from './serverAPI';

const redirectToLoginApp = () => {
  var loginURL = process.env.REACT_APP_LOGIN_URL;

  var callback = {
    'url': window.location.origin,
    'type': 'check-in'
  };
  var form = document.createElement('form');
  var callbackInput = document.createElement('input');

  form.method = 'POST';
  form.action = loginURL;

  callbackInput.value = JSON.stringify(callback);
  callbackInput.name = 'callback';
  callbackInput.type = 'hidden';
  form.appendChild(callbackInput);

  document.body.appendChild(form);

  form.submit();
}

const getLoginToken = () => {
  let querySearch = new URLSearchParams(window.location.search);
  let loginToken = null;
  if (querySearch.has('loginToken'))
  {
    loginToken = querySearch.get('loginToken')
  }
  return loginToken;
}

export const login = (setUsuario) => {
  // Si viene el loginToken como parametro en el request, estamos en el proceso de login
  const loginToken = getLoginToken();

  const actualizarSistema = (sistemaData) => {
    setUsuario(sistemaData.usuario);
    setSistemaData(sistemaData);
  }

  if (loginToken) {
    window.history.replaceState({}, document.title, "/");
    // Tenemos el loginToken para hacer login con el backend
    let postData = {
      accion: 'login',
      type: 'loginToken',
      token: loginToken,
      originType: 'check-in',
      rol: 'ROL_STAFF'
    }

    sendRequest('post', 'usuario', 'login', postData, (response) => {
      if (response.success) {
        if (response.data.authToken)
        {
          setAuthToken(response.data.authToken);
        }
        actualizarSistema(response.data);
        localStorage.setItem('mimqcoBeneficioActual', JSON.stringify({}));
      }
    })
  }
  else
  {
    // No vino el loginToken, Intentamos obtener info del server
    sendRequest('post', 'usuario', 'getMe', {originType: 'backoffice', rol: 'ROL_STAFF'}, (response) => {
    // sendRequest('post', null, 'me', {}, (response) => {
      if (response.success) {
        // Si devuelve OK, estamos logueados y tenemos el authToken
        if (response.data.authToken) {
          setAuthToken(response.data.authToken);
        }
        actualizarSistema(response.data);
      } else {
        // si devuelve usuario invalido, tenemos que hacer redirect a la loginApp para obtener el loginToken
        // console.log('sin usuario', response);
        redirectToLoginApp();
      }

    });
  }
};

export const logout = (setUsuario) => {
  // Intentamos obtener info del server
  localStorage.setItem('mimqcoBeneficioActual', JSON.stringify({}));
  sendRequest('post', 'usuario', 'logout', {}, (response) => {
    if (response.success)
    {
      // Si devuelve OK, estamos logueados y tenemos el authToken
      setUsuario(null);
    }
  });
};