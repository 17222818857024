import React from "react";
import { sistema } from "../../model/Sistema";

export default ({ id, activo, empresa, descripcion, descuento, nombre, places, web, is_maquinero, logo,  ...props }) => {

  return (
    <div className="beneficio-card" >
{/*       <div className="beneficio-card__avatar">
        <i className="beneficio-card__avatar__notification-icon icon--circle hidden"></i>
        {logo
          ? <div className="beneficio-card__avatar__image" src={logo} style={{backgroundImage: `url(${logo})`}} alt="" />
          : <div className="beneficio-card__avatar__image">
              <span className="beneficio-card__avatar__text">
                {nombre ? nombre[0] : ""}
              </span>
            </div>
        }
      </div>
 */}      <h1 className="beneficio-card__nombre">
        {empresa}
      </h1>
      <p className="beneficio-card__titulo">
        {nombre}
      </p>
      <p className="beneficio-card__descuento">
        {descripcion}
      </p>
      {descuento &&
        <p className="beneficio-card__descuento">
          {descuento}
        </p>}
    </div>
  );
};
