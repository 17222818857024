import { login } from '../lib/auth';
import Usuario from './Usuario';
export default class Sistema {
  constructor() {
    this.prestador = null;
    this.actual = localStorage.actual ? JSON.parse(localStorage.actual) : {};
    this.usuario = null;
    this.noImageURL = localStorage.sistema?.noImageURL ? localStorage.sistema.noImageURL : null;
  }

  saveActual() {
    localStorage.actual = JSON.stringify(this.actual);
  }

  loadActual() {
    this.actual = JSON.parse(localStorage.actual);
  }

  refreshUser(setUsuario) {
    login((usuarioData) => {
      this.usuario = new Usuario(usuarioData);
      if (setUsuario) {
        setUsuario(this.usuario);
      }
    });
  }

}

export const sistema = new Sistema();